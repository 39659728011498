<script lang="ts" setup>
import DemosLogoutAlert from "~/components/Demos/LogoutAlert.vue";

definePageMeta({
  middleware: ["auth", "app"],
});

useHead({
  script: [
    {
      src: "https://cdn.headwayapp.co/widget.js",
      async: true,
    },
  ],
});

const isLoggingOut = ref(false);
const logout = async () => {
  if (isPublicDemo.value) {
    useBaseAlert("Oops!", DemosLogoutAlert, true);
    return;
  }

  isLoggingOut.value = true;
  await useAppUserData().logout();
  isLoggingOut.value = false;
};

const tourBuilderStore = useTourBuilder();
const { isBuildingTour } = storeToRefs(tourBuilderStore);

const appUserStore = useAppUserData();
const { isUserSystemAdmin, isDemoAccount, isPublicDemo, hasStudentAccount } =
  storeToRefs(appUserStore);

const capabilitesServiceStore = useCapabilitiesServiceStore();
const { hasSubscription, isInitialized } = storeToRefs(capabilitesServiceStore);

const studentHref = computed(() => {
  const host = new URL(window.location.href).host;

  return `https://students.${host}`;
});
</script>

<template>
  <div class="overflow-hidden">
    <BaseAppBar class="fixed top-0 left-0 z-[888]">
      <template #leading>
        <div class="h-full p-2 flex fled-row items-center">
          <img src="/img/logo.png" class="h-full" />
        </div>
      </template>
      <template #actions>
        <ClientOnly>
          <div
            v-if="isInitialized == true && hasStudentAccount"
            class="bg-secondary-default bg-opacity-10 border-dashed border-secondary-default border-2 p-1 mr-4 rounded"
          >
            <a :href="studentHref">
              <BaseButton color="secondary">
                Looking for the student portal?
              </BaseButton>
            </a>
          </div>
          <div
            v-if="isInitialized == true && hasSubscription != true"
            class="bg-primary-default bg-opacity-10 border-dashed border-primary-default border-2 p-1 mr-4 rounded"
          >
            <NuxtLink to="/order/cart/create">
              <BaseButton> Subscribe to IEW Gradebook </BaseButton>
            </NuxtLink>
          </div>
          <div class="mr-4">
            <AnnouncementsButton />
          </div>
          <div class="mr-4">
            <BaseFeedbackButton />
          </div>
          <HelpButton class="mr-4" />
          <ReportProblemButton class="mr-4" :is-consolidated="true" />
          <BaseTextButton
            v-tooltip="'Logout'"
            color="danger"
            class="mr-4"
            :show-spinner="isLoggingOut"
            @click="logout"
          >
            <icon name="material-symbols:logout" size="25" />
          </BaseTextButton>
          <NuxtLink to="/settings" class="mr-4">
            <BaseTextButton
              v-tooltip="'Settings'"
              data-step-id="settings-nav-button"
            >
              <div class="flex flex-row items-center">
                <icon name="ic:baseline-settings" size="25" />
              </div>
            </BaseTextButton>
          </NuxtLink>
          <span class="flex flex-row items-center">
            <VDropdown v-if="isUserSystemAdmin || isDemoAccount">
              <BaseTextButton>
                <icon name="material-symbols:more-vert" size="25" />
              </BaseTextButton>
              <template #popper>
                <div class="w-[300px]">
                  <div
                    v-if="isUserSystemAdmin"
                    class="flex flex-row items-center p-4"
                  >
                    <BaseToggle v-model="isBuildingTour" />
                    <span class="ml-2"> Enable Tour Builder </span>
                  </div>
                  <ResetDemoButton v-if="isDemoAccount" class="w-full" />
                  <ToggleDemoModeButton v-if="isDemoAccount" class="w-full" />
                  <CreateDemoButton v-if="isUserSystemAdmin" class="w-full" />
                </div>
              </template>
            </VDropdown>
          </span>
        </ClientOnly>
      </template>
    </BaseAppBar>

    <div class="pt-[60px] h-screen overflow-hidden">
      <DocumentsContainer />
    </div>
  </div>
</template>

<style></style>
