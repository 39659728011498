<script setup lang="ts">
import { useModal } from "vue-final-modal";
import ReportProblemForm from "~/components/ReportProblem/Form.client.vue";

const props = defineProps({
  isConsolidated: {
    type: Boolean,
    default: false,
  },
});

const showFeedback = () => {
  const { open, close } = useModal({
    component: ReportProblemForm,
    attrs: {
      type: "feedback",
      onClose: () => {
        close();
      },
    },
  });

  open();
};
</script>

<template>
  <BaseTextButton v-tooltip="'Feedback'" @click="showFeedback">
    <icon name="icon-park-outline:email-push" size="25" />
  </BaseTextButton>
</template>

<style scoped></style>
